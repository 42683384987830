.fixed-td-width{
    width: 84px;
    text-overflow: ellipsis;
    overflow: hidden;
}
.user-history-list .title-pic {
    margin-bottom: 40px;
}
.user-history-list .title-pic .left {
    display: inline-block;
    width: 150px;
    height: 150px;
    /* background-color: antiquewhite; */
    margin-right: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.user-history-list .title-pic .right {
    display: inline-block;

}
.user-history-list .ant-list-item {
    background-color: #f5f5f5;
    -webkit-box-shadow: 0 2px 4px 0 #000;
    box-shadow: 0 2px 4px 0 #bbb;
}
.user-history-list .ant-list-item  .content {
    padding-top: 10px;
    text-align: center;
}
.user-history-list .ant-list-item   .content .img-box-one {
    width: 150px;
    height: 150px;
    display: inline-block;
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    background-color: #000;
    margin: 0 5px;
  }
  .user-history-list  p {
    margin: 0;
    line-height: 20px;
    height: 25px;
    /* max-width: 280px; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .title-h4 {
      font-weight: 700;
  }
  .required::before{
    content: '* ';
    color: #f00;
  }
  .employeeForm .ant-select-disabled{
    color:rgba(1,1,1,0.7);
  }
  .employeeForm .ant-input[disabled]{
    color:rgba(1,1,1,0.7);
    }
 .employeeForm .ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice{
    color:rgba(1,1,1,0.7);
 }
 .employeeForm  .ant-radio-disabled + span{
 color:rgba(1,1,1,0.7);
 }
  