@import url(/iconfont/iconfont.css);
body {
  margin: 0;
  padding: 0;
  font-family:Share Tech;
}

.fixed-td-width{
    width: 84px;
    text-overflow: ellipsis;
    overflow: hidden;
}
.user-history-list .title-pic {
    margin-bottom: 40px;
}
.user-history-list .title-pic .left {
    display: inline-block;
    width: 150px;
    height: 150px;
    /* background-color: antiquewhite; */
    margin-right: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.user-history-list .title-pic .right {
    display: inline-block;

}
.user-history-list .ant-list-item {
    background-color: #f5f5f5;
    box-shadow: 0 2px 4px 0 #bbb;
}
.user-history-list .ant-list-item  .content {
    padding-top: 10px;
    text-align: center;
}
.user-history-list .ant-list-item   .content .img-box-one {
    width: 150px;
    height: 150px;
    display: inline-block;
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    background-color: #000;
    margin: 0 5px;
  }
  .user-history-list  p {
    margin: 0;
    line-height: 20px;
    height: 25px;
    /* max-width: 280px; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .title-h4 {
      font-weight: 700;
  }
  .required::before{
    content: '* ';
    color: #f00;
  }
  .employeeForm .ant-select-disabled{
    color:rgba(1,1,1,0.7);
  }
  .employeeForm .ant-input[disabled]{
    color:rgba(1,1,1,0.7);
    }
 .employeeForm .ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice{
    color:rgba(1,1,1,0.7);
 }
 .employeeForm  .ant-radio-disabled + span{
 color:rgba(1,1,1,0.7);
 }
  
.info .availabilities .item {
    background-color: #ffffff;
    height: 200px;
    padding:12px;
}
.info .availabilities .item .title {
    color: rgba(0,0,0,.45);
    font-size: 14px;
}
.info .availabilities .item .left,.info .availabilities .item .right {
    text-align: center;
    margin-top: 12px;
}
.info .availabilities .item .right {
    margin-top: 12px;
}
.info .availabilities .item p {
    margin:0;
    padding: 0;
}
.ant-descriptions-row > th, .ant-descriptions-row > td {
    padding-bottom : 12px;
}
.info .item .info-panal-row {
    text-align: left;
    padding-left: 20px;
}
.info .availabilities .item .left .circle {
    border-radius: 50%;
    width:80px;
    height: 80px;
    border:4px solid #7CC95D;
    text-align: center;
    line-height: 75px;
    margin:0 auto;
    font-size: 20px;
    /* box-sizing: content-box; */
}
.info .availabilities .item .left .tpu-chart {
    width:100px;
    height: 100px;
    margin: 0 auto;
    margin-top:-10px;
}
.info .availabilities .item .left .circle .icon-yidongshujuwangqia {
    font-size: 30px;
}
.info .content-chart .item {
    background-color: #ffffff;
    height: 592px;
    /* padding: 24px; */
}
.info .content-chart .time-content {
    font-size: 20px;
    font-weight: 400;
    padding-left:20px;
    padding-top:18px;
}
.info .content-chart .e-chart .theme {
    color: rgba(0,0,0,.45);
    font-size: 14px;
}
.info .content-chart .e-chart {
  
    text-align: center;
    height: 500px;
    box-sizing: border-box;
    /* display: flex;
    justify-content: space-between; */
}
.info .content-chart .e-chart .left,.info .content-chart .e-chart .right {
    width: 300px;
    margin: 0 auto;
    height: 300px;
}

/* .ant-upload.ant-upload-select-picture-card {
    border-radius: 50%!important;
} */
.search-by-image .content {
    text-align: center;
}
.search-by-image .content .img-box {
    width: 120px;
    height: 120px;
    background-color: rgb(238, 240, 243);
    display: inline-block;
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    margin: 0 5px;
    position: relative;
}
.search-by-image .content .img-box .score {
    position: absolute;
    right:0px;
    top:0;
}
.ant-progress-circle .ant-progress-inner {
background-color:rgb(238, 240, 243)!important;
}
.search-by-image .content p {
    margin: 0;
    line-height: 20px;
    height: 25px;
    width: 100%;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .search-by-image .card-content {
    height: calc(100vh - 222px);
    overflow-y: auto;
  }
  .search-by-image .ant-upload-picture-card-wrapper {
    zoom: 1;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .search-by-image .ant-form-item {
    margin-bottom : 10px;
  }
  .search-by-image .ant-card-head-title {
    white-space:pre-wrap;
  }
  .search-by-image .ant-upload.ant-upload-select-picture-card > .ant-upload {
    position: relative;
  }
  .search-by-image .btn-clear-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: 18px;
    height: 18px;
    background: #F5222D;
    border-radius: 25px;
    color: #FFF;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    cursor: pointer;
  }
.compare-face > .title {
    text-align: center;
    margin-bottom: 50px;
}
.flext-justify-btw {
    display: flex;
    display: -webkit-flex;
    -webkit-justify-content: space-between;
    -moz-box-pack:space-between;
    -webkit-moz-box-pack:space-between;
    box-pack:space-between;
    justify-content: space-between;
    align-items: center;
}
.flext-justify-center {
    display: flex;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -moz-box-pack:center;
    -webkit-moz-box-pack:center;
    box-pack:center;
    justify-content: center; 
    
} 
.compare-face .content {
    margin: 0 auto;
}
/* 上传2个图片 */
.compare-face .content .left { 
    background-color: rgb(246,247,251); 
    width: 670px;
    margin-right: 20px;
    padding: 24px;
    height: 348px;

}
.compare-face .btn-clear-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: 18px;
    height: 18px;
    background: #F5222D;
    border-radius: 25px;
    color: #FFF;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    cursor: pointer;
  }
  .compare-face .content .left .left-face, .compare-face .content .left .right-face {
      position: relative;
      height: 300px;
      overflow: hidden;
  }
.compare-face .content .left .avatar-uploader > .ant-upload{
    width:300px;
    height: 300px;
    margin:0;
    padding: 0;
    box-sizing: border-box;
}
/* .compare-face .content .left .avatar-uploader > .ant-upload > .ant-upload{
    width:300px;
    height: 300px;
} */
.compare-face .content .right{
    height: 348px;
    width:250px;
    border:1px solid #efefef;
}
.compare-face .content .right .title{
    text-align: center;
    color: #008dff;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    background-color: rgb(246,247,251); 
}
.compare-face .content .right .result{
    padding: 24px;
}

.App {
  text-align: center;
}

*{
  font-family: Share Tech !important;
}
.ant-form-item-label{
  text-align: right !important;
}
a{
  color:#0EB4E5;
}
table{
  table-layout: fixed;
}
td{
  display: table-cell;
  white-space: pre;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  overflow: hidden;
}
.ant-menu-dark, .ant-menu-dark .ant-menu-sub,.ant-layout-sider{
  background-color: #262626 !important;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected{
  background-color: #0EB4E5;
}
.ant-btn-primary{
  color: #fff;
  border-color: #0EB4E5;
  background-color: #0EB4E5;
}
.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}
.login-container{
  margin: 0px auto;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.login-page{
  height: 100%;
  background-size: cover !important;
}
#root{
  height: 100%;
}
.header{
  height: 64px;
  background-color: #FFFFFFFF;
  box-shadow: 0 1px 4px 0 RGBA(0, 21, 41, 0.12);
  position: fixed;
  width: 100%;
  z-index: 1;
}
.logo{
  /* height: 30px; */
  width: 140px;
  margin: 18px 0;
  margin-left: 24px;
  /* line-height: 30px; */
}
.header i{
  cursor: pointer;
}

.user-panal{
  position: fixed;
  right: 10px;
  top: 0;
  padding: 0 12px;
}
.user-panal:hover{
  background-color: RGBA(14, 180, 229, 0.25);
  cursor: pointer;
}


.footer-link{
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 164px;
  margin-bottom: 8px;
  font-size: 14px;
}

.info-statistics{
  display: flex;
  display: -webkit-flex;
}
.white{
  background-color: #FFFFFFFF;
}
.info-panal-title{
  font-size: 16px;
  color: RGBA(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.info-panal-row{
  height: 54px;
  text-align: center;
  line-height: 54px;
  border-bottom: 1px solid rgba(232,232,232,1);
}
.info-panal-row:nth-child(2n+1){
  background-color: rgba(250,250,250,1);
  border-radius:4px 4px 0px 0px;
}
.add-user-tab{
  display: flex;
  justify-content: center;
}
.gate-item{
  width: 290px;
  height: 356px;
  border-radius: 2px;
  padding-right: 24px;
  margin-bottom: 14px;
  /* overflow: hidden;
  text-overflow: ellipsis; */
}
.gate-add-button-container{
  line-height: 356px;
  text-align: center;
}

.gate-add-button:hover{
  cursor: pointer;
  color: #0EB4E5;
  border-color: #0EB4E5;
  background-color: #FFFFFFFF;
}
.gates-container{
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}
.event-search-expanded{
  position: absolute;
  right: 0;
  bottom: -80px;
  height: 40px;
  z-index: 2;
}
.ant-form-item-label{
  text-align: left;
}
.algorithm-form{
  background-color: #FFFFFFFF;
  height: 500px;
  margin: 24px;
  text-align: center;
  padding-top: 136px;
}
.live-stream-sider-tab{
  box-shadow:0px 2px 8px 0px rgba(0,0,0,0.09);
  border-radius:3px;
  padding-left: 16px;
  min-height: 723px;
}
.overflow{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.red {
  color: #ff0000;
}
.vxgplayer{
  border: 2px solid !important;
  margin: 0 !important;
  box-shadow: 0 0 0 !important;
  width: 265px !important;
  height: 198px !important;
  z-index: 0 !important;
}
.vxgplayer-error{
  left: 0 !important;
}
.sider-menu-box{
  height: 100vh;
  position: relative;
  overflow:  hidden;
}
.sider-container-content{
  position: absolute;
  width: 217px;
  left: 0;
  top: 0; 
  right: -17px;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}
/* .sider-menu-box::-webkit-scrollbar{
  display: none;
  width: 0 !important;
} */
.div-p{
  margin-bottom: 1rem;
}
.cursor {
  color:#0EB4E5;
  cursor: pointer;
}
.underline-cursor {
  color:#0EB4E5;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 10px;
}
.video-grid{
  display: flex;
  justify-content: center;
  width: 140px;
  align-items: center;
}
.video-grid span{
 font-size: 30px;
 color:#d9d9d9;
 cursor: pointer;
}
/* .video-grid .page{
  color: #1890ff;
} */
.video-grid .grid:hover{
  color: #1890ff;
}
.video-grid .selected {
  color: #1890ff;
}
.video-grid span:nth-child(3){
  font-size: 34px;
}
  /* 设置滚动条的样式 */
::-webkit-scrollbar {
  width:4px;
  height: 4px;
  /* background-color: aqua; */
  }

  /* 滚动槽 */
  ::-webkit-scrollbar-track {
  border-radius:50%;
  }

  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
  border-radius:2px;
  background: #dddddd;
  }
  .tips-text {
    color:rgba(0,0,0,0.4);
    font-size: 14px;
  }
  .flex{
    display: flex;
  }
  .flex-center {
    justify-content:center;
    -moz-box-pack:center;
    -webkit-moz-box-pack:center;
    box-pack:center;
  }
  .flex-align-center {
    align-items:center;
    box-align:center;
    -moz-box-align:center;
    -webkit-box-align:center;
  }
 
