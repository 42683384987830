.info .availabilities .item {
    background-color: #ffffff;
    height: 200px;
    padding:12px;
}
.info .availabilities .item .title {
    color: rgba(0,0,0,.45);
    font-size: 14px;
}
.info .availabilities .item .left,.info .availabilities .item .right {
    text-align: center;
    margin-top: 12px;
}
.info .availabilities .item .right {
    margin-top: 12px;
}
.info .availabilities .item p {
    margin:0;
    padding: 0;
}
.ant-descriptions-row > th, .ant-descriptions-row > td {
    padding-bottom : 12px;
}
.info .item .info-panal-row {
    text-align: left;
    padding-left: 20px;
}
.info .availabilities .item .left .circle {
    border-radius: 50%;
    width:80px;
    height: 80px;
    border:4px solid #7CC95D;
    text-align: center;
    line-height: 75px;
    margin:0 auto;
    font-size: 20px;
    /* box-sizing: content-box; */
}
.info .availabilities .item .left .tpu-chart {
    width:100px;
    height: 100px;
    margin: 0 auto;
    margin-top:-10px;
}
.info .availabilities .item .left .circle .icon-yidongshujuwangqia {
    font-size: 30px;
}
.info .content-chart .item {
    background-color: #ffffff;
    height: 592px;
    /* padding: 24px; */
}
.info .content-chart .time-content {
    font-size: 20px;
    font-weight: 400;
    padding-left:20px;
    padding-top:18px;
}
.info .content-chart .e-chart .theme {
    color: rgba(0,0,0,.45);
    font-size: 14px;
}
.info .content-chart .e-chart {
  
    text-align: center;
    height: 500px;
    box-sizing: border-box;
    /* display: flex;
    justify-content: space-between; */
}
.info .content-chart .e-chart .left,.info .content-chart .e-chart .right {
    width: 300px;
    margin: 0 auto;
    height: 300px;
}