@import '~antd/dist/antd.css';
@import "/iconfont/iconfont.css";
.App {
  text-align: center;
}

*{
  font-family: Share Tech !important;
}
.ant-form-item-label{
  text-align: right !important;
}
a{
  color:#0EB4E5;
}
table{
  table-layout: fixed;
}
td{
  display: table-cell;
  white-space: pre;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  overflow: hidden;
}
.ant-menu-dark, .ant-menu-dark .ant-menu-sub,.ant-layout-sider{
  background-color: #262626 !important;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected{
  background-color: #0EB4E5;
}
.ant-btn-primary{
  color: #fff;
  border-color: #0EB4E5;
  background-color: #0EB4E5;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}
.login-container{
  margin: 0px auto;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.login-page{
  height: 100%;
  background-size: cover !important;
}
#root{
  height: 100%;
}
.header{
  height: 64px;
  background-color: #FFFFFFFF;
  box-shadow: 0 1px 4px 0 RGBA(0, 21, 41, 0.12);
  position: fixed;
  width: 100%;
  z-index: 1;
}
.logo{
  /* height: 30px; */
  width: 140px;
  margin: 18px 0;
  margin-left: 24px;
  /* line-height: 30px; */
}
.header i{
  cursor: pointer;
}

.user-panal{
  position: fixed;
  right: 10px;
  top: 0;
  padding: 0 12px;
}
.user-panal:hover{
  background-color: RGBA(14, 180, 229, 0.25);
  cursor: pointer;
}


.footer-link{
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 164px;
  margin-bottom: 8px;
  font-size: 14px;
}

.info-statistics{
  display: flex;
  display: -webkit-flex;
}
.white{
  background-color: #FFFFFFFF;
}
.info-panal-title{
  font-size: 16px;
  color: RGBA(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.info-panal-row{
  height: 54px;
  text-align: center;
  line-height: 54px;
  border-bottom: 1px solid rgba(232,232,232,1);
}
.info-panal-row:nth-child(2n+1){
  background-color: rgba(250,250,250,1);
  border-radius:4px 4px 0px 0px;
}
.add-user-tab{
  display: -webkit-flex;
  display: flex;
  justify-content: center;
}
.gate-item{
  width: 290px;
  height: 356px;
  border-radius: 2px;
  padding-right: 24px;
  margin-bottom: 14px;
  /* overflow: hidden;
  text-overflow: ellipsis; */
}
.gate-add-button-container{
  line-height: 356px;
  text-align: center;
}

.gate-add-button:hover{
  cursor: pointer;
  color: #0EB4E5;
  border-color: #0EB4E5;
  background-color: #FFFFFFFF;
}
.gates-container{
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}
.event-search-expanded{
  position: absolute;
  right: 0;
  bottom: -80px;
  height: 40px;
  z-index: 2;
}
.ant-form-item-label{
  text-align: left;
}
.algorithm-form{
  background-color: #FFFFFFFF;
  height: 500px;
  margin: 24px;
  text-align: center;
  padding-top: 136px;
}
.live-stream-sider-tab{
  box-shadow:0px 2px 8px 0px rgba(0,0,0,0.09);
  border-radius:3px;
  padding-left: 16px;
  min-height: 723px;
}
.overflow{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.red {
  color: #ff0000;
}
.vxgplayer{
  border: 2px solid !important;
  margin: 0 !important;
  box-shadow: 0 0 0 !important;
  width: 265px !important;
  height: 198px !important;
  z-index: 0 !important;
}
.vxgplayer-error{
  left: 0 !important;
}
.sider-menu-box{
  height: 100vh;
  position: relative;
  overflow:  hidden;
}
.sider-container-content{
  position: absolute;
  width: 217px;
  left: 0;
  top: 0; 
  right: -17px;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}
/* .sider-menu-box::-webkit-scrollbar{
  display: none;
  width: 0 !important;
} */
.div-p{
  margin-bottom: 1rem;
}
.cursor {
  color:#0EB4E5;
  cursor: pointer;
}
.underline-cursor {
  color:#0EB4E5;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 10px;
}
.video-grid{
  display: flex;
  justify-content: center;
  width: 140px;
  align-items: center;
}
.video-grid span{
 font-size: 30px;
 color:#d9d9d9;
 cursor: pointer;
}
/* .video-grid .page{
  color: #1890ff;
} */
.video-grid .grid:hover{
  color: #1890ff;
}
.video-grid .selected {
  color: #1890ff;
}
.video-grid span:nth-child(3){
  font-size: 34px;
}
  /* 设置滚动条的样式 */
::-webkit-scrollbar {
  width:4px;
  height: 4px;
  /* background-color: aqua; */
  }

  /* 滚动槽 */
  ::-webkit-scrollbar-track {
  border-radius:50%;
  }

  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
  border-radius:2px;
  background: #dddddd;
  }
  .tips-text {
    color:rgba(0,0,0,0.4);
    font-size: 14px;
  }
  .flex{
    display:-webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
  .flex-center {
    -webkit-justify-content:center;
    justify-content:center;
    -moz-box-pack:center;
    -webkit-moz-box-pack:center;
    box-pack:center;
  }
  .flex-align-center {
    align-items:center;
    -webkit-align-items:center;
    box-align:center;
    -moz-box-align:center;
    -webkit-box-align:center;
  }
 