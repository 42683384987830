
/* .ant-upload.ant-upload-select-picture-card {
    border-radius: 50%!important;
} */
.search-by-image .content {
    text-align: center;
}
.search-by-image .content .img-box {
    width: 120px;
    height: 120px;
    background-color: rgb(238, 240, 243);
    display: inline-block;
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    margin: 0 5px;
    position: relative;
}
.search-by-image .content .img-box .score {
    position: absolute;
    right:0px;
    top:0;
}
.ant-progress-circle .ant-progress-inner {
background-color:rgb(238, 240, 243)!important;
}
.search-by-image .content p {
    margin: 0;
    line-height: 20px;
    height: 25px;
    width: 100%;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .search-by-image .card-content {
    height: calc(100vh - 222px);
    overflow-y: auto;
  }
  .search-by-image .ant-upload-picture-card-wrapper {
    zoom: 1;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .search-by-image .ant-form-item {
    margin-bottom : 10px;
  }
  .search-by-image .ant-card-head-title {
    white-space:pre-wrap;
  }
  .search-by-image .ant-upload.ant-upload-select-picture-card > .ant-upload {
    position: relative;
  }
  .search-by-image .btn-clear-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: 18px;
    height: 18px;
    background: #F5222D;
    border-radius: 25px;
    color: #FFF;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    cursor: pointer;
  }