.compare-face > .title {
    text-align: center;
    margin-bottom: 50px;
}
.flext-justify-btw {
    display: flex;
    display:-webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-justify-content: space-between;
    -moz-box-pack:space-between;
    -webkit-moz-box-pack:space-between;
    box-pack:space-between;
    justify-content: space-between;
    align-items: center;
}
.flext-justify-center {
    display: flex;
    display:-webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -moz-box-pack:center;
    -webkit-moz-box-pack:center;
    box-pack:center;
    justify-content: center; 
    
} 
.compare-face .content {
    margin: 0 auto;
}
/* 上传2个图片 */
.compare-face .content .left { 
    background-color: rgb(246,247,251); 
    width: 670px;
    margin-right: 20px;
    padding: 24px;
    height: 348px;

}
.compare-face .btn-clear-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: 18px;
    height: 18px;
    background: #F5222D;
    border-radius: 25px;
    color: #FFF;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    cursor: pointer;
  }
  .compare-face .content .left .left-face, .compare-face .content .left .right-face {
      position: relative;
      height: 300px;
      overflow: hidden;
  }
.compare-face .content .left .avatar-uploader > .ant-upload{
    width:300px;
    height: 300px;
    margin:0;
    padding: 0;
    box-sizing: border-box;
}
/* .compare-face .content .left .avatar-uploader > .ant-upload > .ant-upload{
    width:300px;
    height: 300px;
} */
.compare-face .content .right{
    height: 348px;
    width:250px;
    border:1px solid #efefef;
}
.compare-face .content .right .title{
    text-align: center;
    color: #008dff;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    background-color: rgb(246,247,251); 
}
.compare-face .content .right .result{
    padding: 24px;
}
